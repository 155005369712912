











































































import { Component, Vue } from 'vue-property-decorator';
import { transliterate } from 'transliteration';
import { mapState } from 'vuex';

import IconLecture from '@/components/_icons/IconLecture.vue';
import IconPereod from '@/components/_icons/IconPereod.vue';
import IconGlobus from '@/components/_icons/IconGlobus.vue';
import IconBillboard from '@/components/_icons/IconBillboard.vue';
import IconRadio from '@/components/_icons/IconRadio.vue';
import IconTelevision from '@/components/_icons/IconTelevision.vue';
import IconWorldWide from '@/components/_icons/IconWorldWide.vue';
import IconClose from '@/components/_icons/IconClose.vue';
import IconArrowNavCarusel from '../components/_icons/IconArrowNavCarusel.vue';

import cases from '@/data/cases';
import config from '@/config';
import VueSlickCarousel from 'vue-slick-carousel';

import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';

@Component({
  components: {
    IconLecture,
    IconPereod,
    IconGlobus,
    IconBillboard,
    IconRadio,
    IconTelevision,
    IconWorldWide,
    IconClose,
    IconArrowNavCarusel,
    VueSlickCarousel
  },
  computed: {
    ...mapState(['mobile'])
  }
})
export default class PageCase extends Vue {
  private selectCase: any = undefined;

  private billboard = false;

  private optionsCarusel = {
    arrows: false,
    dots: false,
    infinite: true,
    initialSlide: 0,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '0',
    swipeToSlide: true
  };

  private nextSlide() {
    const carusel: any = this.$refs.casecarusel;

    carusel[0].next();
  }
  private prevSlide() {
    const carusel: any = this.$refs.casecarusel;

    carusel[0].prev();
  }

  private logo(item: string, color?: boolean) {
    return config.getLogo(item, color);
  }

  private mediaFind(array: string[], view: string) {
    if (array.indexOf(view) !== -1) {
      return true;
    }
  }

  private caseImage(company: string, nameCase: string, count?: number) {
    if (count) {
      return config.URL + company + '/' + transliterate(nameCase).toLowerCase() + '-' + count + '.png';
    } else {
      return config.URL + company + '/' + transliterate(nameCase).toLowerCase() + '.png';
    }
  }

  private options = {
    start: 0,
    dir: 'v',
    duration: 1000
  };

  private created() {
    this.selectCase = cases.find((i: any, index: number) => {
      if (i.company === this.$route.params.name) {
        return i;
      }
    });
  }
}
