






















import { Component, Vue } from 'vue-property-decorator';
import { mapMutations } from 'vuex';
import IconClose from '@/components/_icons/IconClose.vue';

@Component({
  components: {
    IconClose
  },
  methods: {
    ...mapMutations('popups', ['closeModal'])
  }
})
export default class BaseModal extends Vue {}
