
















import { Component, Vue } from 'vue-property-decorator';
import Modal from '@/components/_shared/base/baseModal.vue';
import BaseInput from '@/components/_shared/base/baseInput.vue';
import { SMTPClient } from 'emailjs';
import emailjs from 'emailjs';

@Component({
  components: {
    Modal,
    BaseInput
  }
})
export default class ModalInviteToTheTender extends Vue {
  private obj = {
    contact: '',
    product: '',
    email: '',
    telephone: '',
    period: ''
  };

  private changeContact(str: string) {
    this.obj.contact = str;
  }
  private changeProduct(str: string) {
    this.obj.product = str;
  }
  private changeEmail(str: string) {
    this.obj.email = str;
  }
  private changeTelephone(str: string) {
    this.obj.telephone = str;
  }
  private changePeriod(str: string) {
    this.obj.period = str;
  }

  private send() {
    const server = emailjs.server.connect({
      user: 'hi@medialogic.com.ua',
      password: 'cvbnyteg03',
      host: 'medialogic.com.ua',
      ssl: false
    });

    server.send(
      {
        text: 'Hey howdy',
        from: 'NodeJS',
        to: 'Valentyn <v.domanskyi5@gmail.com>',
        cc: '',
        subject: 'Greetings'
      },
      function(err: any, message: any) {
        console.log(err || message);
      }
    );
  }
}
