import Vue from 'vue';
import Vuex from 'vuex';
import popups from './modules/popups';
import validate from './modules/validate';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    smartphone: false,
    mobile: false,
    isNotFull: false,
    loading: true,
    finish: false
  },
  mutations: {
    changeStatusMobile(state: any, payload: boolean) {
      state.mobile = payload;
    },
    changeStatusSmartphone(state: any, payload: boolean) {
      state.smartphone = payload;
    },
    finish(state: any) {
      if (!state.smartphone) {
        state.finish = true;
        setTimeout(() => {
          state.loading = false;
          setTimeout(() => {
            state.finish = false;
          }, 700);
        }, 1500);
      } else {
        state.loading = false;
        state.finish = false;
      }
    }
  },
  actions: {},
  modules: {
    popups,
    validate
  }
});
