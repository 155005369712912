













import { Component, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';
import HeroBlock from '@/components/_sections/Hero.vue';
import SectionAboutUs from '@/components/_sections/AboutUs.vue';
import SectionTeam from '@/components/_sections/Team.vue';
import SectionService from '@/components/_sections/Service.vue';
import SectionTrust from '@/components/_sections/Trust.vue';
import SectionContact from '@/components/_sections/Contact.vue';
import ModalInviteToTheTender from '@/components/_shared/modals/modalInviteToTheTender.vue';

@Component({
  components: {
    HeroBlock,
    SectionAboutUs,
    SectionTeam,
    SectionService,
    SectionTrust,
    SectionContact,
    ModalInviteToTheTender
  },
  computed: {
    ...mapState('popups', ['inviteToTheTender', 'blur']),
    ...mapState(['loading', 'smartphone'])
  }
})
export default class PageHome extends Vue {}
