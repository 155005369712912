import Vue from 'vue';
import VueRouter from 'vue-router';
import PageHome from '@/views/Home.vue';
import PageBrief from '@/views/Brief.vue';
import PageCase from '../views/Case.vue';
import store from '@/store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: PageHome
  },
  {
    path: '/brief',
    name: 'Brief',
    component: PageBrief
  },
  {
    path: '/:name',
    name: 'Case',
    component: PageCase
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: function(to) {
    if (to.hash) {
      return {
        selector: to.hash
      };
    }
  }
});

router.afterEach((to, from) => {
  setTimeout(() => {
    store.commit('finish');
  }, 5000);
});

export default router;
