import Vue from 'vue';
import VueI18n from 'vue-i18n';
import ru from '@/i18n/ru.json';
import ua from '@/i18n/ua.json';

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: 'ua',
  messages: {
    ru,
    ua
  }
});
