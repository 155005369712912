
























import { Component, Vue } from 'vue-property-decorator';
import SectionTitle from '@/components/_shared/base/sectionTitle.vue';
import Logo from '@/components/_icons/Logo.vue';
import config from '@/config';

import cases from '@/data/cases';

@Component({
  components: {
    SectionTitle,
    Logo
  }
})
export default class SectionTrust extends Vue {
  private logo(item: string, color?: boolean) {
    return config.getLogo(item, color);
  }

  private data() {
    return { cases };
  }
}
