const state = {
  modalDialog: false,
  inviteToTheTender: false,
  blur: false
};

const mutations = {
  showModal(state: any, payload: string) {
    state[payload] = true;
    state.modalDialog = true;
    state.blur = true;
  },

  closeModal(state: any) {
    state.blur = false;
    for (const key in state) {
      state[key] = false;
    }
  }
};

const actions = {
  //
};

const getters = {
  //
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
