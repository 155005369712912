






import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class SectionFooter extends Vue {
  private year = new Date().getUTCFullYear();
}
