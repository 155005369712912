class Config {
  public GAPI = 'AIzaSyAKyfXxrEljTEBZ0zSopC4Y6pgDpmAdAYc';
  public URL = 'http://medialogic.com.ua/dev/';

  public getLogo(item: string, color?: boolean) {
    if (color) {
      return this.URL + item + '/' + 'logo_' + item + '-c.png';
    } else {
      return this.URL + item + '/' + 'logo_' + item + '.png';
    }
  }
}

export default new Config();
