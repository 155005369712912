import { render, staticRenderFns } from "./baseInput.vue?vue&type=template&id=4f28c689&"
import script from "./baseInput.vue?vue&type=script&lang=ts&"
export * from "./baseInput.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports