


































import { Component, Vue } from 'vue-property-decorator';
import SectionTitle from '@/components/_shared/base/sectionTitle.vue';
import team from '@/data/team.json';
import IconArrowNavCarusel from '@/components/_icons/IconArrowNavCarusel.vue';
import VueSlickCarousel from 'vue-slick-carousel';

import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';

@Component({
  components: {
    SectionTitle,
    IconArrowNavCarusel,
    VueSlickCarousel
  }
})
export default class SectionTeam extends Vue {
  private options = {
    arrows: false,
    dots: false,
    infinite: true,
    initialSlide: 0,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    centerMode: false,
    centerPadding: '0',
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 1075,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 754,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  private nextSlide() {
    const carusel: any = this.$refs.carusel;
    carusel.next();
  }
  private prevSlide() {
    const carusel: any = this.$refs.carusel;
    carusel.prev();
  }

  private data() {
    return { team };
  }
}
