



























































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { required, email, min, image, size, ext } from 'vee-validate/dist/rules';
import { mapState } from 'vuex';

import validations from '@/settings/validations.json';

import { ValidationObserver, ValidationProvider, validate, extend, configure } from 'vee-validate';

export interface InterfaceBaseInput {
  id?: number;
  title: string;
  checked: boolean;
  vhtml?: boolean;
  required?: boolean;
  value?: any;
  disabled?: boolean;
}

@Component({
  components: {
    ValidationObserver,
    ValidationProvider
  },
  computed: {
    ...mapState('validate', ['showErrorValid'])
  }
})
export default class BaseInput extends Vue {
  // Rules for validate
  private validations: any = validations;

  $refs!: {
    input: InstanceType<typeof HTMLFormElement>;
    provider: InstanceType<typeof ValidationProvider>;
    observer: InstanceType<typeof ValidationObserver>;
  };

  @Prop(Boolean) private autocomplete?: boolean;
  @Prop(Boolean) private column?: boolean;
  @Prop(Boolean) private confirmation?: boolean;
  @Prop(Boolean) private disabled?: boolean;
  @Prop(Boolean) private disabledConfirmationTitle?: boolean;
  @Prop(Boolean) private disabledTitle?: boolean;
  @Prop(Boolean) private iconLeft?: boolean;
  @Prop(Boolean) private immediate?: boolean;
  @Prop(Boolean) private multiple?: boolean;
  @Prop(Boolean) private readonly?: boolean;
  @Prop(Boolean) private required?: boolean;
  @Prop(Boolean) private showPassword?: boolean;
  @Prop(Boolean) private textarea?: boolean;
  @Prop(Boolean) private toFocus?: boolean;

  @Prop([String, Number]) private value?: string | number;
  @Watch('value') private watchValue(current: string | number, old: string | number) {
    this.v = current;
  }

  @Prop(String) private accept?: string;
  @Prop(String) private confirmationPlaceholder?: string;
  @Prop(String) private confirmationTitle?: string;
  @Prop(String) private name?: string;
  @Prop(String) private placeholder?: string;
  @Prop(String) private title?: string;
  @Prop(String) private type?: string;
  @Prop(String) private fileType?: string;

  @Prop(Number) private max?: number;
  @Prop(Number) private maxlength?: number;
  @Prop(Number) private maxSizeFile?: number;
  @Prop(Number) private min?: number;
  @Prop(Number) private cols?: number;
  @Prop(Number) private rows?: number;

  @Prop(Array) private data?: InterfaceBaseInput[];
  @Watch('data') private watchData(current: InterfaceBaseInput[], old: InterfaceBaseInput[]) {
    this.data = current;
    this.dataInput = current;
  }

  private showErrorValid!: boolean;

  private t = 'text'; // internal variable type
  private T = ''; // internal variable title
  private confirmationT = 'Password confirmation';
  private confirmationP = 'Password confirmation';
  private n = ''; // internal variable name
  private p = ''; // internal variable placeholder
  private v: string | number = ''; // internal variable value
  private confirmationPassword: string | number = ''; // internal variable for confirmation password

  private readyFile = '';

  private textareaCols = 30;
  private textareaRows = 10;

  private focus = false; // for add class
  private confirmationFocus = false; // for add class

  private dataInput: InterfaceBaseInput[] | [] = [];
  private typeFile = '';

  private messages = {
    password: 'Password confirmation does not match'
  };

  private handleChange(str: any) {
    if (this.required) {
      if (this.confirmation && this.type === 'password') {
        this.dispatch(this.$refs.provider.flags.valid);
      } else {
        validate(this.v, this.validations[this.type ? this.type : this.t]).then((result: any) => {
          this.dispatch(result.valid);
        });
      }
    } else {
      this.return(this.v);
    }
  }

  private handleValidate() {
    validate(this.v, this.validations[this.type ? this.type : this.t]).then((result: any) => {
      this.addToState(result.valid);
    });
  }

  private handleValidateCheckbox() {
    for (const i in this.dataInput) {
      if (this.dataInput[i].required) {
        if (this.dataInput[i].checked) {
          this.dataInput[i].checked = false;
        }
        this.addToState(this.dataInput[i].checked, this.dataInput[i].title);
      }
    }
  }

  private handleValidateRadio() {
    if (this.required) {
      this.addToState(false, this.name);
      for (const i in this.dataInput) {
        this.dataInput[i].checked = false;
      }
    }
  }

  private inputCheckbox(id: number) {
    if (!this.dataInput[id].disabled) {
      this.dataInput[id].checked = !this.dataInput[id].checked;
      if (this.dataInput[id].required) {
        this.dispatch(this.dataInput[id].checked, this.dataInput[id].title, this.dataInput[id]);
      }
      this.return(this.dataInput);
    }
  }

  private inputRadio(id: number) {
    if (!this.dataInput[id].disabled) {
      for (const i in this.dataInput) {
        this.dataInput[i].checked = false;
      }
      this.dataInput[id].checked = !this.dataInput[id].checked;
      if (this.required) {
        this.dispatch(true, this.name);
      }
      this.return(this.dataInput);
    }
  }

  private addRequiredInRule() {
    const type: string = this.type ? this.type : this.t;
    if (type !== 'password') {
      if (this.validations[type]) {
        this.validations[type] = 'required|' + this.validations[type];
      } else {
        this.validations[type] = 'required';
      }
    }
  }

  private addSizeFileInRule(size: number) {
    const type: string = this.type ? this.type : this.t;
    const fileType = this.fileType ? this.fileType : type;

    const s = `size:${size}`;

    if (this.validations[fileType]) {
      this.validations[fileType] = this.validations[fileType] + '|' + s;
    } else {
      this.validations[type] = s;
    }
  }

  private addToState(value: any, key?: string) {
    if (key) {
      this.$store.commit('validate/addInputValidRequired', { key, value });
    } else {
      this.$store.commit('validate/addInputValidRequired', { key: this.T, value });
    }
  }

  private dispatch(value: any, key?: string, returnValue?: any) {
    if (key) {
      this.$store.commit('validate/changeInputValidRequired', { key, value });
      if (returnValue) {
        this.return(returnValue);
      }
    } else {
      this.$store.commit('validate/changeInputValidRequired', { key: this.T, value });
      this.return(this.v);
    }
  }

  private upload(event: any) {
    this.$refs.provider.validate(event).then((res: any) => {
      if (res.valid) {
        const input = event.target;
        if (input.files && input.files[0]) {
          this.typeFile = input.files[0].name.split('.').pop();
          this.n = input.files[0].name;
          const reader = new FileReader();
          reader.onload = (e: any) => {
            this.readyFile = e.target.result;
            this.returnIMG();
          };
          reader.readAsDataURL(input.files[0]);
        }
      }
    });
  }

  private return(data: any) {
    this.$emit('return', data);
  }

  private returnIMG() {
    const data = { name: this.n, img: this.readyFile, type: this.typeFile };
    this.return(data);
  }

  private selectType() {
    if (this.type === 'password' && this.confirmation) {
      return this.validations.confirmation;
    } else if (this.type === 'file') {
      if (this.fileType) {
        return this.validations[this.fileType];
      } else {
        return this.validations[this.type];
      }
    } else if (this.type) {
      return this.validations[this.type];
    } else {
      return this.validations[this.t];
    }
  }

  private basicVariable() {
    if (this.type) {
      this.t = this.type;
      if (this.type === 'radio' || this.type === 'checkbox') {
        if (this.data) {
          this.dataInput = this.data;
        }
      }
    }
    if (this.name) {
      this.n = this.name;
    } else {
      this.n = this.t;
    }
    if (this.title) {
      this.T = this.title;
    } else {
      this.T = this.t;
    }
    if (this.confirmationTitle) {
      this.confirmationT = this.confirmationTitle;
    }
    if (this.confirmationPlaceholder) {
      this.confirmationP = this.confirmationPlaceholder;
    }
    if (this.placeholder) {
      this.p = this.placeholder;
    } else if (!this.placeholder && this.title) {
      this.p = this.title;
    } else {
      this.p = this.t;
    }
    if (this.cols) {
      this.textareaCols = this.cols;
    }
    if (this.rows) {
      this.textareaRows = this.rows;
    }
    if (this.value) {
      if (this.type !== 'password') {
        this.v = this.value;
      }
    }
  }

  private extends =
    (extend('required', {
      ...required,
      message: 'This field is required'
    }),
    extend('email', { ...email }),
    extend('min', { ...min, message: 'Password must be at least 8 characters long' }),
    extend('password', {
      params: ['target'],
      validate(value: any, { target }: any) {
        return value === target;
      },
      message: 'Password confirmation does not match'
    }),
    extend('size', {
      ...size,
      message: (field: string) => 'The ' + field + ' value is not MAX.'
    }),
    extend('ext', {
      ...ext
    }),
    extend('image', { ...image, message: 'Wrong type of file.' }));

  private created() {
    if (this.toFocus) {
      this.$nextTick(() => {
        this.$refs.input.focus();
      });
    }

    this.basicVariable();

    if (this.type === 'file' && this.maxSizeFile) {
      this.addSizeFileInRule(this.maxSizeFile);
    }
    if (this.type === 'checkbox' || this.type === 'radio') {
      if (this.type === 'checkbox') {
        this.handleValidateCheckbox();
      } else if (this.type === 'radio') {
        this.handleValidateRadio();
      }
    } else {
      if (this.required) {
        this.addRequiredInRule();
        this.handleValidate();
      }
    }
  }
}
