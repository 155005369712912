







































































































































































































































































































































































































import { Component, Vue, Prop } from 'vue-property-decorator';

import AdvertisingMarket from './timeline/AdvertisingMarket.vue';
import Brainstorming from './timeline/Brainstorming.vue';
import Email from './timeline/Email.vue';
import MediaTrainings from './timeline/MediaTrainings.vue';
import Projects from './timeline/Projects.vue';
import Specialists from './timeline/Specialists.vue';

@Component({
  components: {
    AdvertisingMarket,
    Specialists,
    Brainstorming,
    Projects,
    MediaTrainings,
    Email,
  }
})
export default class Timeline extends Vue {
  @Prop(Object) private data!: DataTimeline;
}
