























































import { Component, Vue } from 'vue-property-decorator';
import IconMediaAcademy from '@/components/_icons/IconMediaAcademy.vue';
import IconBaing from '@/components/_icons/IconBaing.vue';
import IconDeveloper from '@/components/_icons/IconDeveloper.vue';
import IconPlanning from '@/components/_icons/IconPlanning.vue';
import IconResearch from '@/components/_icons/IconResearch.vue';
import IconArrowNavCarusel from '@/components/_icons/IconArrowNavCarusel.vue';
import SectionTitle from '@/components/_shared/base/sectionTitle.vue';
import VueSlickCarousel from 'vue-slick-carousel';

import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';

@Component({
  components: {
    SectionTitle,
    IconBaing,
    IconDeveloper,
    IconMediaAcademy,
    IconPlanning,
    IconResearch,
    IconArrowNavCarusel,
    VueSlickCarousel
  }
})
export default class SectionService extends Vue {
  private options = {
    adaptiveHeight: false,
    variableWidth: false,
    centerPadding: '0',
    arrows: false,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    edgeFriction: 0.35
  };

  private nextSlide() {
    const carusel: any = this.$refs.carusel;
    carusel.next();
  }
  private prevSlide() {
    const carusel: any = this.$refs.carusel;
    carusel.prev();
  }
}
