






import { Component, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';

@Component({
  components: {},
  computed: {
    ...mapState('popups', ['blur']),
    ...mapState(['loading', 'smartphone'])
  }
})
export default class App extends Vue {
  private updateWidth() {
    const size: number = window.innerWidth;

    if (size <= 1280) {
      this.$store.commit('changeStatusSmartphone', true);
      if (size <= 980) {
        this.$store.commit('changeStatusMobile', true);
      } else {
        this.$store.commit('changeStatusMobile', false);
      }
    } else {
      this.$store.commit('changeStatusSmartphone', false);
    }
  }

  private created() {
    this.updateWidth();
    window.addEventListener('resize', this.updateWidth);
  }
}
