import Vue from 'vue';
import App from './App.vue';

import store from './store';
import router from './router';
import config from './config';

import PortalVue from 'portal-vue';
import vClickOutside from 'v-click-outside';
import VueFullpage from 'fullpage-vue';
import VueGoogleMaps from 'vue-googlemaps';

import { i18n } from './i18n';

import 'vue-googlemaps/dist/vue-googlemaps.css';
import '@/scss/main.scss';
import 'fullpage-vue/src/fullpage.css';

Vue.config.productionTip = false;

Vue.use(PortalVue);
Vue.use(vClickOutside);
Vue.use(VueFullpage);
Vue.use(VueGoogleMaps, {
  load: {
    apiKey: config.GAPI,
    useBetaRenderer: false
  }
});

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app');
