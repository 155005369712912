const state = {
  validRequired: [],
  valid: false,
  showErrorValid: false
};

const mutations = {
  addInputValidRequired(state: any, payload: any) {
    const obj: any = {};
    obj.key = payload.key;
    obj.value = payload.value;
    state.validRequired.push(obj);
  },
  changeInputValidRequired(state: any, payload: any) {
    for (let a = 0; a < state.validRequired.length; a++) {
      if (payload.key === state.validRequired[a].key) {
        state.validRequired[a].value = payload.value;
      }
    }
  },
  checkValidRequired(state: any) {
    let count = 0;

    for (let r = 0; r < state.validRequired.length; r++) {
      if (!state.validRequired[r].value) {
        count++;
      }
    }

    if (count === 0) {
      state.valid = true;
    } else {
      state.valid = false;
      state.showErrorValid = true;
    }
  },
  clearValidRequired(state: any) {
    state.valid = false;
    state.validRequired = [];
  }
};

const actions = {
  //
};

const getters = {
  //
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
