














































import { Component, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';
import SectionTitle from '@/components/_shared/base/sectionTitle.vue';
import IconHome from '@/components/_icons/IconHome.vue';
import IconPhone from '@/components/_icons/IconPhone.vue';
import IconSvoi from '@/components/_icons/IconSvoi.vue';
import Logo from '@/components/_icons/Logo.vue';
import LogoDOM from '@/components/_icons/LogoDOM.vue';
import MapStyle from '@/data/mapstyle.json';
import Footer from '@/components/_sections/Footer.vue';

@Component({
  components: {
    SectionTitle,
    IconHome,
    IconPhone,
    IconSvoi,
    Logo,
    LogoDOM,
    Footer
  },
  computed: {
    ...mapState('popups', ['blur']),
    ...mapState(['mobile', 'smartphone'])
  }
})
export default class SectionContact extends Vue {
  private MapStyle: any = MapStyle;

  private phones: string[] = ['(044)400 80 91', '(044)400 95 11'];

  private map: any = {
    center: MapStyle.center,
    zoom: 15,
    disableDefaultUI: false,
    fullscreenControl: false,
    mapTypeControl: false,
    panControl: false,
    rotateControl: false,
    scaleControl: false,
    streetViewControl: false,
    zoomControl: false,
    tilt: 0,
    styles: MapStyle.style
  };

  private showModal(name: string) {
    this.$store.commit('popups/showModal', name);
  }
}
