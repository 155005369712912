
















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class TimelineAdvertisingMarket extends Vue {
  @Prop(Number) public year!: number;

  public checkLocale(locale: string) {
    return this.$i18n.locale === locale;
  }
}
