import biopharma from './cases/biopharma.json';
import daikin from './cases/daikin.json';
import deltaMedical from './cases/delta-medical.json';
import esparma from './cases/esparma.json';
import euroLifecare from './cases/euro-lifecare.json';
import gree from './cases/gree.json';
import grindex from './cases/grindex.json';
import honda from './cases/honda.json';
import oschadbank from './cases/oschadbank.json';
import radioLux from './cases/radio-lux.json';
import rdoUkraine from './cases/rdo-ukraine.json';
import tac from './cases/tac.json';
import talisman from './cases/talisman.json';
import unipharm from './cases/unipharm.json';
import vaillent from './cases/vaillent.json';
import yuriaPharma from './cases/yuria-pharma.json';
import veres from './cases/veres.json';
import leifheit from './cases/leifheit.json';
import taya from './cases/taya.json';
import vishpha from './cases/vishpha.json';

const cases = [
  biopharma,
  daikin,
  deltaMedical,
  esparma,
  euroLifecare,
  gree,
  grindex,
  honda,
  oschadbank,
  radioLux,
  rdoUkraine,
  tac,
  talisman,
  unipharm,
  vaillent,
  yuriaPharma,
  veres,
  leifheit,
  taya,
  vishpha
];

export default cases;