

























import { Component, Vue } from 'vue-property-decorator';
import SectionTitle from '@/components/_shared/base/sectionTitle.vue';
import IconArrowLi from '@/components/_icons/IconArrowLi.vue';
import Timeline from '@/components/_icons/Timeline.vue';

@Component({
  components: {
    SectionTitle,
    IconArrowLi,
    Timeline
  }
})
export default class SectionAboutUs extends Vue {
  private timeline = false;

  private dataTimeline: DataTimeline = {
    year: this.year(),
    specialists: 200,
    projects: 100,
    brainstorming: '2 000',
    training: 790,
    mail: '375 673'
  };

  private showTimeline() {
    if (this.$route.name === 'Home') {
      const elem = document.getElementById('about-us');
      // @ts-ignore
      const box = elem.getBoundingClientRect();

      if (box.y <= 110) {
        this.timeline = true;
      }
    }
  }

  private year() {
    const current = new Date().getUTCFullYear();
    const startYear = 2013;
    return current - startYear;
  }

  private created() {
    window.addEventListener('scroll', this.showTimeline);
  }
}
