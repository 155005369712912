


































import { Component, Vue } from 'vue-property-decorator';
import Logo from './_icons/Logo.vue';
import { mapState } from 'vuex';
import IconMenuBar from './_icons/IconMenuBar.vue';
import IconClose from './_icons/IconClose.vue';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

@Component({
  components: {
    Logo,
    IconMenuBar,
    IconClose
  },
  computed: {
    ...mapState(['mobile', 'loading', 'finish', 'smartphone'])
  }
})
export default class Navbar extends Vue {
  private active = false;

  private toggleActive() {
    this.active = !this.active;
  }

  private goTo(id: string) {
    if (!this.$store.state.loading) {
      this.toggleActive();
      const app: any = document.getElementById(id);
      app.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    }
  }

  private changeLanguage(language: string) {
    this.$i18n.locale = language;
    // if (!this.$store.state.loading) {
    //   console.log(`change language on: ${language}`);
    // }
  }
}
