














import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class TimelineEmail extends Vue {
  @Prop(String) public mail!: string;

  public checkLocale(locale: string) {
    return this.$i18n.locale === locale;
  }
}
