


















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class Logo extends Vue {
  @Prop(Boolean) private white?: boolean;

  private triangle = '#00aeef';
  private medialogic = '#1d1d1d';

  private created() {
    if (this.white) {
      this.triangle = '#fff';
      this.medialogic = '#fff';
    }
  }
}
