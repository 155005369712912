





































































import { Component, Vue } from 'vue-property-decorator';
import { transliterate as tr, slugify } from 'transliteration';
import BaseInput from '@/components/_shared/base/baseInput.vue';
import Logo from '@/components/_icons/Logo.vue';
import brief from '@/data/brief.json';

import emailjs from 'emailjs';

@Component({
  components: {
    Logo,
    BaseInput
  }
})
export default class PageBrief extends Vue {
  private modelka: any = {};

  private brief!: any;

  private changeModel(value: string, headerID: number, inputID: number) {
    let key = '';

    if (this.brief[headerID].input[inputID].title) {
      key = this.firstWord(this.brief[headerID].input[inputID].title);
    } else {
      key = this.firstWord(this.brief[headerID].title);
    }

    this.modelka[key] = value;
    console.log(this.modelka);
  }

  private firstWord(str: string) {
    const array = str.split(' ');
    return slugify(`${array[0]}`);
  }

  private created() {
    document.addEventListener('keypress', (event) => {
      if (event.code === 'Enter') {
        console.log(this.modelka);
      }
    });
  }

  private returnInputTitle(title: string) {
    if (!title) {
      // console.log('"Введите текст"');
      return 'Введите текст';
    } else {
      // console.log(title);
      return title;
    }
  }
  private returnInput(input: any) {
    if (input.type === 'group') {
      const array: any = [];
      for (let i = 0; i <= input.value.length - 1; i++) {
        for (let a = 0; a <= input.value[i].input.length - 1; a++) {
          array.push(input.value[i].input[a]);
        }
      }
      return array;
    } else {
      return input.input;
    }
  }

  private send() {
    console.log(this.modelka);
    // // Email.send({
    // //   Host: 'smtp.yourisp.com',
    // //   Username: 'username',
    // //   Password: 'password',
    // //   To: 'them@website.com',
    // //   From: 'you@isp.com',
    // //   Subject: 'This is the subject',
    // //   Body: 'And this is the body'
    // // }).then((message: any) => alert(message));
    // const server = emailjs.server.connect({
    //   user: 'hi@medialogic.com.ua',
    //   password: 'cvbnyteg03',
    //   host: 'skm263.hostsila.org.',
    //   ssl: false
    // });
    // server.send(
    //   {
    //     text: 'i hope this works',
    //     from: 'you <username@your-email.com>',
    //     to: 'someone <someone@your-email.com>, another <another@your-email.com>',
    //     cc: 'else <else@your-email.com>',
    //     subject: 'testing emailjs'
    //   },
    //   function(err, message) {
    //     console.log(err || message);
    //   }
    // );
  }

  private data() {
    return { brief };
  }
}
