






















import { Component, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';
import Navbar from '@/components/Navbar.vue';
import VideoBg from 'vue-videobg';

@Component({
  components: {
    Navbar,
    VideoBg
  },
  computed: {
    ...mapState(['mobile', 'smartphone'])
  }
})
export default class SectionHero extends Vue {
  private goTo(id: string) {
    const app: any = document.getElementById(id);
    app.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    });
  }
}
