




import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class SectionTitle extends Vue {
  @Prop(String) private title!: string;
  @Prop(Boolean) private white?: boolean;
}
